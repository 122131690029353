import { AnyAction, createAction, createReducer} from "@reduxjs/toolkit";
import {RootState} from "./store";
import {ActionsObservable, ofType, StateObservable} from "redux-observable";
import {catchError, map, mergeMap} from "rxjs/operators";
import {ajax} from "rxjs/ajax";
import {of} from "rxjs";
import {ENDPOINT} from "./const";


const loginEpic = (action$: ActionsObservable<AnyAction>, state$: StateObservable<RootState>) => action$.pipe(
    ofType(LOGIN.type),
    mergeMap((action) => ajax.post(`${ENDPOINT}/api/login`, {
            username: action.payload.username,
            password: action.payload.password
        }, {'Content-Type': 'application/json'}).pipe(
        map(res => res.response),
        map(({token}) => LOGIN_OK(token)),
        catchError(error => of(LOGIN_FAILED())))
    )
);


interface Credentials {
    username: string;
    password: string;
}


const LOGIN = createAction<Credentials>('LOGIN');
const LOGIN_OK = createAction<string>('LOGIN_OK');
const LOGIN_FAILED = createAction<void>('LOGIN_FAILED');
const LOGOUT = createAction<void>('LOGOUT');


const initialState: {
    token: string,
    loggedIn: boolean,
    error: boolean,
} = {
    token: "",
    loggedIn: false,
    error: false,
};

const user = createReducer(initialState, builder =>
    builder
        .addCase(LOGIN_OK, (state, action) => {
            return {
                ...state,
                token: action.payload,
                loggedIn: true
            }
        })
        .addCase(LOGIN_FAILED, (state, action) => {
            return {
                ...state,
                token: "",
                loggedIn: false,
                error: true,
            }
        })
        .addCase(LOGOUT, (state, action) => {
            return {
                ...state,
                token: "",
                loggedIn: false,
                error: false,
            }
        })
);

const tokenSelector = (state: RootState) => state.user.token;
const loginErrorSelector = (state: RootState) => state.user.error;
const isLoggedInSelector = (state: RootState) => state.user.loggedIn;


export {
    user,
    LOGIN,
    LOGOUT,
    tokenSelector,
    isLoggedInSelector,
    loginEpic,
    loginErrorSelector
}