import React from 'react';
import ButtonAppBar from "./components/AppBar";
import Container from "@material-ui/core/Container";
import DataTable from "./components/Table.";
import CustomizedSnackbars from "./components/Snackbar";
import {useSelector} from "react-redux";
import {isLoggedInSelector} from "./utils/user";
import Login from "./components/Login";

const App: React.FC = () => {
    const isLoggedIn: boolean = useSelector(isLoggedInSelector);

    return (
        <>

            <ButtonAppBar/>

            {isLoggedIn && (
                <Container maxWidth={"xl"} style={{paddingTop: 31}}>
                    <DataTable/>
                </Container>
            )}

            {!isLoggedIn && (
                <Container maxWidth={"xl"} style={{paddingTop: 31}}>
                    <Login/>
                </Container>
            )}


            <CustomizedSnackbars/>


        </>


    );
}

export default App;
