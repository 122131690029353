import React from 'react';
import {Snackbar} from "@material-ui/core";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import {useSelector} from "react-redux";
import {isErrorSnackbarOpen, isSnackbarOpen} from "../utils/orders";
import {green, red} from "@material-ui/core/colors";


export default function CustomizedSnackbars() {
    const snackbarOpen: boolean = useSelector(isSnackbarOpen);
    const errorSnackbarOpen: boolean = useSelector(isErrorSnackbarOpen);
    return (
        <>

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={errorSnackbarOpen}
                autoHideDuration={2000}
            >
                <SnackbarContent message="Export nicht erfolgreich" style={{

                    backgroundColor: red[600],

                }}/>
            </Snackbar>

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={snackbarOpen}
                autoHideDuration={2000}
            >
                <SnackbarContent message="Export erfolgreich" style={{

                    backgroundColor: green[600],

                }}/>
            </Snackbar>
        </>


    );
}


