import {createAction, createReducer} from "@reduxjs/toolkit";
import {RootState} from "./store";
import {Order} from "../types/orders";

interface Credentials {
    username: string;
    password: string;
}

const increment = createAction<string[]>('increment');
const FETCH_ORDERS = createAction<void>('FETCH_ORDERS');
const FETCH_ORDERS_DONE = createAction<Order[]>('FETCH_ORDERS_DONE');

const TOGGLE_SELECTED_ORDER = createAction<number>("TOGGLE_SELECTED_ORDER");
const TOGGLE_ALL_ORDERS = createAction<void>("TOGGLE_ALL_ORDERS");

const EXPORT_ORDERS = createAction<void>('EXPORT_ORDERS');
const EXPORT_ORDERS_DONE = createAction<void>('EXPORT_ORDERS_DONE');
const EXPORT_ORDERS_ERROR = createAction<void>('EXPORT_ORDERS_ERROR');
const CLOSE_SNACKBAR = createAction<void>('CLOSE_SNACKBAR');


const initialState: {
    data: Order[]
    selected: number[]
    snackbar: boolean
    snackbarError: boolean
    exporting: boolean
} = {
    data: [],
    selected: [],
    snackbar: false,
    snackbarError: false,
    exporting: false
};

const orders = createReducer(initialState, builder =>
    builder
        .addCase(FETCH_ORDERS_DONE, (state, action) => {
            return {
                ...state,
                data: action.payload
            }
        })
        .addCase(TOGGLE_SELECTED_ORDER, (state, action) => {
            let newSelected;

            if (state.selected.includes(action.payload)) {
                newSelected = [...state.selected];
                newSelected = newSelected.filter(orderId => orderId !== action.payload)
            } else {
                newSelected = [...state.selected, action.payload];

            }
            return  {
                ...state,
                selected: newSelected
            }
        })
        .addCase(TOGGLE_ALL_ORDERS, (state, action) => {
            let newSelected: number[] = [];

            const allIds = state.data.map(order => order.orders_id);

            if (state.selected.length === allIds.length) {
                newSelected = []
            } else {
                newSelected = [...allIds]
            }

            return {
                ...state,
                selected: newSelected
            }
        })
        .addCase(EXPORT_ORDERS, (state, action) => {
            return {
                ...state,
                exporting: true
            }
        })
        .addCase(EXPORT_ORDERS_DONE, (state, action) => {
            return {
                ...state,
                selected: [],
                snackbar: true,
                exporting: false
            }
        })
        .addCase(EXPORT_ORDERS_ERROR, (state, action) => {
            return {
                ...state,
                selected: [],
                snackbarError: true,
                exporting: false
            }
        })
        .addCase(CLOSE_SNACKBAR, (state, action) => {
            return {
                ...state,
                snackbar: false,
                snackbarError: false
            }
        })

);

const dataSelector = (state: RootState) => state.orders.data;
const selectedOrderSelector = (state: RootState) => state.orders.selected;
const selectedOrderCountSelector = (state: RootState) => state.orders.selected.length;
const isExportingSelector = (state: RootState) => state.orders.exporting;
const isSnackbarOpen = (state: RootState) => state.orders.snackbar;
const isErrorSnackbarOpen = (state: RootState) => state.orders.snackbarError;


export {
    increment,
    FETCH_ORDERS,
    orders,
    FETCH_ORDERS_DONE,
    dataSelector,
    selectedOrderSelector,
    selectedOrderCountSelector,
    isSnackbarOpen,
    isErrorSnackbarOpen,
    isExportingSelector,
    TOGGLE_SELECTED_ORDER,
    TOGGLE_ALL_ORDERS,
    EXPORT_ORDERS,
    EXPORT_ORDERS_DONE,
    EXPORT_ORDERS_ERROR,
    CLOSE_SNACKBAR
}