import {ajax} from 'rxjs/ajax';
import {ActionsObservable, ofType, StateObservable} from "redux-observable";
import {catchError, concatMap, delay, map, mergeMap, tap} from "rxjs/operators";
import {Action} from "redux";
import {
    CLOSE_SNACKBAR,
    EXPORT_ORDERS, EXPORT_ORDERS_DONE, EXPORT_ORDERS_ERROR,
    FETCH_ORDERS,
    FETCH_ORDERS_DONE,
} from "./orders";
import {RootState} from "./store";
import {of} from "rxjs";
import {Order} from "../types/orders";
import {tokenSelector} from "./user";
import {ENDPOINT} from "./const";


const fetchOrderEpic = (action$: ActionsObservable<Action>, state$: StateObservable<RootState>) => action$.pipe(
    ofType(FETCH_ORDERS.type),
    mergeMap(() => ajax.getJSON<Order[]>(`${ENDPOINT}/api/orders`, {Authorization: `Bearer ${tokenSelector(state$.value)}`}).pipe(
        map(FETCH_ORDERS_DONE)
        )
    )
);

const exportOrderEpic = (action$: ActionsObservable<Action>, state$: StateObservable<RootState>) => action$.pipe(
    ofType(EXPORT_ORDERS.type),
    mergeMap(() => ajax.post(`${ENDPOINT}/api/export`, {data: state$.value.orders.selected}, {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${tokenSelector(state$.value)}`
        }).pipe(
        mergeMap(() => of(EXPORT_ORDERS_DONE(), FETCH_ORDERS(), CLOSE_SNACKBAR())),
        tap(console.log),
        concatMap((value, index) => {
            if (index === 2) {
                return of(value).pipe(delay(3000));
            } else {
                return of(value);
            }
        }),
        catchError(err => of(EXPORT_ORDERS_ERROR(), CLOSE_SNACKBAR())),
        concatMap((value, index) => {
            if (index === 1) {
                return of(value).pipe(delay(3000));
            } else {
                return of(value);
            }
        }),
        )
    )
);


export {fetchOrderEpic, exportOrderEpic};
