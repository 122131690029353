import {
    Action,
    combineReducers,
    configureStore,
    getDefaultMiddleware,
} from '@reduxjs/toolkit'
import logger from 'redux-logger'
import {ActionsObservable, combineEpics, createEpicMiddleware, StateObservable} from "redux-observable";
import {catchError} from "rxjs/operators";
import {orders} from "./orders";
import {exportOrderEpic, fetchOrderEpic} from "./orders.epic";
import {loginEpic, user} from "./user";


const rootEpic = (action$: ActionsObservable<Action>, store$: StateObservable<any>, dependencies: any) => (
    combineEpics(
        fetchOrderEpic,
        exportOrderEpic,
        loginEpic
    )(action$, store$, dependencies).pipe(
        catchError((error, source) => {
            console.error(error);
            return source;

        })
    )
);


const rootReducer = combineReducers({
    orders,
    user,
});
export type RootState = ReturnType<typeof rootReducer>


const epicMiddleware = createEpicMiddleware();
const middleware = [
    ...getDefaultMiddleware(),
    logger,
    epicMiddleware
];

const store = configureStore({
    reducer: rootReducer,
    middleware,
    devTools: process.env.NODE_ENV !== 'production',
});

epicMiddleware.run(rootEpic);

export {
    store
}