import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {useDispatch, useSelector} from "react-redux";
import {
    dataSelector, EXPORT_ORDERS,
    FETCH_ORDERS, isExportingSelector,
    selectedOrderCountSelector,
    selectedOrderSelector, TOGGLE_ALL_ORDERS,
    TOGGLE_SELECTED_ORDER
} from "../utils/orders";
import {IconButton, Toolbar} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import {Order} from "../types/orders";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReplayIcon from '@material-ui/icons/Replay';

const useStyles = makeStyles({
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
    title: {
        flex: '1 1 100%',
    },
});

export default function DataTable() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const data: Order[] = useSelector(dataSelector);
    const selectedOrders: number[] = useSelector(selectedOrderSelector);
    const selectedOrdersCount: number = useSelector(selectedOrderCountSelector);
    const isExporting: boolean = useSelector(isExportingSelector);


    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [])

    const fetchData = () => {
        dispatch(FETCH_ORDERS());
    };

    const toggleOrder = (orderId: number) => {
        dispatch(TOGGLE_SELECTED_ORDER(orderId));
    };

    const toggleAllOrders = () => {
        dispatch(TOGGLE_ALL_ORDERS());
    };

    const isSelected = (orderId: number) => {
        return selectedOrders.includes(orderId);
    };

    const exportOrders = () => {
        dispatch(EXPORT_ORDERS());
    };

    return (
        <Paper className={classes.root}>
            <Toolbar>
                <Typography className={classes.title} color="inherit" variant="subtitle1">
                    {selectedOrdersCount} ausgewählt
                </Typography>
                {isExporting && <CircularProgress style={{marginRight: 20}} size={26}/>}

                <IconButton
                    style={{marginRight: "5px"}}
                    onClick={fetchData}
                >
                    <ReplayIcon/>
                </IconButton>
                <Button
                    onClick={exportOrders}
                    variant="contained"
                    disabled={selectedOrdersCount === 0 || isExporting}
                >
                    Exportieren
                </Button>
            </Toolbar>
            <Table className={classes.table} aria-label="simple table" size={"small"}>
                <TableHead>
                    <TableRow>
                        <TableCell padding="checkbox">
                            <Checkbox
                                indeterminate={false}
                                checked={data.length === selectedOrdersCount}
                                onClick={toggleAllOrders}
                            />
                        </TableCell>
                        <TableCell>ID</TableCell>
                        <TableCell align="right">Firma</TableCell>
                        <TableCell align="right">Name</TableCell>
                        <TableCell align="right">Straße</TableCell>
                        <TableCell align="right">Adresszusatz</TableCell>
                        <TableCell align="right">PLZ</TableCell>
                        <TableCell align="right">Ort</TableCell>
                        <TableCell align="right">KundenNr</TableCell>
                        <TableCell align="right">Referenz</TableCell>
                        <TableCell align="right">E-Mail</TableCell>
                        <TableCell align="right">Inhalt</TableCell>
                        <TableCell align="right">Gewicht</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map(row => (
                        <TableRow
                            hover
                            key={row.orders_id}
                            onClick={() => toggleOrder(row.orders_id)}
                            selected={isSelected(row.orders_id)}
                        >
                            <TableCell padding="checkbox">
                                <Checkbox
                                    checked={isSelected(row.orders_id)}
                                />
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {row.orders_id}
                            </TableCell>
                            <TableCell align="right">{row.delivery_company}</TableCell>
                            <TableCell align="right">{`${row.delivery_firstname} ${row.delivery_lastname}`}</TableCell>
                            <TableCell align="right">{row.delivery_street_address}</TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right">{row.delivery_postcode}</TableCell>
                            <TableCell align="right">{row.delivery_city}</TableCell>
                            <TableCell align="right">{row.customers_id}</TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right">{row.customers_email_address}</TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    );
}
