import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Lock from '@material-ui/icons/Lock';
import Button from "@material-ui/core/Button";
import { LOGIN, loginErrorSelector} from "../utils/user";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
    root: {
        display: "flex",
        justifyContent: "center",

    },
    input: {
        width: 225,
        marginBottom: 20,
    },
    paper: {
        display: "flex",
        padding: 5,
        paddingTop: 20,
        alignItems: "center",
        flexDirection: "column",
        width: 400,
        overflowX: 'auto',
        height: 400
    },
});

export default function Login() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [mail, setMail] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const loginError: boolean = useSelector(loginErrorSelector);

    const onSubmit = () => {
        dispatch(LOGIN({
            username: mail,
            password
        }))
    }
    return (

        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Typography variant="h4">Login</Typography>
                <Lock style={{marginTop: 20, marginBottom: 40}}/>
                {loginError && (
                    <Typography variant={"body1"} style={{paddingBottom: 10}}>Die eingegebenen Daten sind nicht
                        korrekt.</Typography>
                )}
                <TextField  label="E-Mail" className={classes.input} value={mail} onChange={e => {
                    setMail(e.target.value)
                }}/>
                <TextField type="password" label="Password" className={classes.input}
                           value={password}
                           onChange={e => {
                               setPassword(e.target.value)
                           }}/>
                <Button variant="contained" onClick={onSubmit}>Login</Button>
            </Paper>
        </div>


    );
}